<template>
  <div class="content_body PackageCard" v-loading="loading">
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form :inline="true" size="small" @keyup.enter.native="handleSearch">
            <el-form-item label="套餐卡名称">
              <el-input @clear="handleSearch" v-model="Name" clearable placeholder="输入名称或简称搜索"></el-input>
            </el-form-item>
            <el-form-item label="套餐卡分类">
              <el-select @change="handleSearch" @clear="handleSearch" v-model="PackageCardCategoryID" filterable clearable placeholder="请选择套餐卡分类">
                <el-option v-for="item in category" :key="item.ID" :label="item.Name" :value="item.ID"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否上架">
              <el-select @change="handleSearch" @clear="handleSearch" v-model="isAllowSell" placeholder="请选择" clearable>
                <el-option label="是" :value="true"></el-option>
                <el-option label="否" :value="false"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSearch" v-prevent-click>搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-button v-if="isPackageCardNew" type="primary" size="small" @click="showAddDialog">新增</el-button>
        </el-col>
      </el-row>
    </div>

    <div class="martp_10">
      <el-table size="small" :data="tableData">
        <el-table-column prop="Name" label="套餐卡名称"></el-table-column>
        <el-table-column prop="Alias" label="套餐卡别名"></el-table-column>
        <el-table-column prop="PackageCardCategoryName" label="套餐卡分类"></el-table-column>
        <el-table-column prop="Price" label="销售价格">
          <template slot-scope="scope">￥{{ scope.row.Price | NumFormat }}</template>
        </el-table-column>
        <el-table-column prop="ValidDayName" label="卡有效期"></el-table-column>
        <el-table-column prop="IsAllowLargess" label="是否可以赠送">
          <template slot-scope="scope">{{
            scope.row.IsAllowLargess == true ? "是" : "否"
          }}</template>
        </el-table-column>
        <el-table-column prop="IsAllowSell" label="是否上架">
          <template slot-scope="scope">{{
            scope.row.IsAllowSell == true ? "是" : "否"
          }}</template>
        </el-table-column>
        <el-table-column label="操作" width="145">
          <template slot-scope="scope">
            <el-button v-if="isPackageCardEdit" type="primary" size="small" @click="showEditDialog(scope.row,'edit')">编辑</el-button>
            <el-button type="primary" size="small" @click="showEditDialog(scope.row,'show')">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="text_right pad_15">
        <el-pagination background v-if="paginations.total > 0" @current-change="handleCurrentChange" :current-page.sync="paginations.page" :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total"></el-pagination>
      </div>
    </div>
    <!--增加、编辑弹出框-->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" :close-on-click-modal="false" width="980px">
      <el-tabs v-model="activeName">
        <el-tab-pane label="基本信息" name="1">
          <el-form :model="ruleForm_add" ref="ruleForm_add" :rules="rules_add" label-width="120px" class="demo-ruleForm" size="small">
            <el-row>
              <el-col :span="12">
                <el-form-item label="套餐卡名称" prop="Name">
                  <el-input v-model="ruleForm_add.Name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="套餐卡别名">
                  <el-input v-model="ruleForm_add.Alias"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="套餐卡分类" prop="PackageCardCategoryID">
                  <el-select clearable v-model="ruleForm_add.PackageCardCategoryID" placeholder="请选择套餐卡分类" style="position: absolute">
                    <el-option v-for="item in category" :key="item.ID" :label="item.Name" :value="item.ID"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="售卖日期" prop="saleDate">
                  <el-date-picker clearable v-model="ruleForm_add.saleDate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" unlink-panels></el-date-picker>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="是否上架" prop="IsAllowSell">
                  <el-radio-group v-model="ruleForm_add.IsAllowSell">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="是否可以赠送" prop="IsAllowLargess">
                  <el-radio-group v-model="ruleForm_add.IsAllowLargess">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item label="卡有效期" prop="ValidType">
                  <div>
                    <el-radio-group v-model="ruleForm_add.ValidType">
                      <el-radio :label="3">永久有效</el-radio>
                      <el-radio :label="1">销售后生成</el-radio>
                      <el-radio :label="2">消耗后生成</el-radio>
                    </el-radio-group>
                    <span v-if="ruleForm_add.ValidType != 3">
                      <el-input class="radio_input" v-model="ruleForm_add.ValidDay" type="number" min="0" v-enterNumber2 placeholder="有效期">
                        <template slot="append">天</template>
                      </el-input>
                    </span>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="备注信息">
                  <el-input type="textarea" :rows="4" placeholder="请输入备注信息" v-model="ruleForm_add.Remark"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="详细信息" name="5">
          <div class="dis_flex flex_dir_row">
            <goodsDetail ref="goodsDetail" :Memo="ruleForm_add.Memo" :ImageList="ruleForm_add.ImageList" :OriginalText="ruleForm_add.OriginalText" :Price="ruleForm_add.Price" :Name="ruleForm_add.Name" :isShowOriginal="false" GoodsType="套餐卡" @Memo_change="Memo_change" @ImageListChange="ImageListChange">
              <el-row slot="info">
                <el-col :span="24" class="padlt_10 padrt_10" style="height:40px;line-height:40px">商品信息</el-col>
                <el-col :span="24" class="back_f8 pad_10">
                  <el-col :span="24" class="martp_5">有效期:
                    <span v-if="ruleForm_add.ValidType == 1">购买后 {{ruleForm_add.ValidDay}} 天内有效</span>
                    <span v-if="ruleForm_add.ValidType == 2">第一次使用后 {{ruleForm_add.ValidDay}} 天内有效</span>
                    <span v-if="ruleForm_add.ValidType == 3">永久有效</span>
                  </el-col>
                  <el-col :span="24" class="martp_5">套餐明细: 包含共 {{goodsRangeList.length}} 项商品 <span style="color:#FF8D66">查看明细</span></el-col>
                  <el-col :span="24" class="martp_5">套餐赠送明细: 包含共 {{goodsRangeLargessList.length}} 项商品 <span style="color:#FF8D66">查看明细</span></el-col>
                </el-col>
              </el-row>
            </goodsDetail>
          </div>

        </el-tab-pane>
        <el-tab-pane name="2" label="套餐卡明细">
          <el-row :gutter="20" class="marbm_10">
            <el-col :span="6">
              <el-input placeholder="输入商品名称搜索" size="small" v-model="filterGoodsRangeProjectName" clearable></el-input>
            </el-col>
            <el-col :span="8">
              <el-button type="primary" size="small" @click="addProjectDialog(1)">添加套餐卡明细</el-button>
            </el-col>
            <el-col :span="10">
              <div style="text-align: right; padding: 13px 0px 0px 0px">
                <b>套餐卡总价 : {{ this.ruleForm_add.Price }}</b>
              </div>
            </el-col>
          </el-row>
          <el-table size="small" max-height="400" :data="
              goodsRangeList.filter(
                (data) =>
                  !filterGoodsRangeProjectName ||
                  data.Name.toLowerCase().includes(
                    filterGoodsRangeProjectName.toLowerCase()
                  )
              )
            ">
            <el-table-column prop="Name" label="商品名称" sortable></el-table-column>
            <el-table-column prop="Alias" label="商品类型" sortable></el-table-column>
            <el-table-column prop="OldPrice" label="销售价格"></el-table-column>
            <el-table-column prop="Price" label="套餐价格">
              <template slot-scope="scope">
                <el-input v-enter-number2 size="small" @change="taocanPrice(scope.row)" type="number" min="0" v-model="scope.row.Price"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="Amount" label="数量">
              <template slot-scope="scope">
                <el-input v-enter-number size="small" @change="Componte(scope.row)" type="number" min="1" v-model="scope.row.Amount"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="TotalPrice" label="总价">
              <template slot-scope="scope">
                <el-input size="small" @change="jisuanPrice(scope.row)" type="number" min="0" v-model="scope.row.TotalPrice"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="80px">
              <template slot-scope="scope">
                <el-button type="danger" size="mini" @click="remove(1, scope.row, scope.$index)">删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>

        <el-tab-pane label="套餐卡赠送明细" name="3">
          <el-row :gutter="20" class="marbm_10">
            <el-col :span="6">
              <el-input placeholder="输入商品名称搜索" size="small" v-model="filterGoodsRangeLargessProjectName" clearable></el-input>
            </el-col>
            <el-col :span="12">
              <el-button type="primary" size="small" @click="addProjectDialog(2)">添加套餐卡赠送明细</el-button>
            </el-col>
          </el-row>
          <el-table size="small" max-height="400" :data="
              goodsRangeLargessList.filter(
                (data) =>
                  !filterGoodsRangeLargessProjectName ||
                  data.Name.toLowerCase().includes(
                    filterGoodsRangeLargessProjectName.toLowerCase()
                  )
              )
            ">
            <el-table-column prop="Name" label="商品名称" sortable></el-table-column>
            <el-table-column prop="Alias" label="商品类型" sortable></el-table-column>
            <el-table-column prop="OldPrice" label="销售价格" sortable></el-table-column>
            <el-table-column label="数量/金额(储值卡填写赠送金额)">
              <template slot-scope="scope">
                <el-input v-if="scope.row.Alias == '储值卡'" size="small" @change="zengsongChange(scope.row)" min="1" type="number" v-model="scope.row.TotalPrice" v-enter-number>储值卡</el-input>
                <el-input v-else size="small" @change="zengsongChange(scope.row)" min="1" type="number" v-model="scope.row.Amount" v-enter-number></el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="80px">
              <template slot-scope="scope">
                <el-button type="danger" size="mini" @click="remove(2, scope.row, scope.$index)">删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>

        <el-tab-pane name="4">
          <div slot="label">
            销售范围
            <el-popover placement="top-start" width="200" trigger="hover">
              <p>适用于同级所有节点，则只需勾选父节点。</p>
              <p>比如：适用于所有节点，只需勾选“顶级/第一个”节点。</p>
              <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
            </el-popover>
          </div>
          <el-scrollbar class="el-scrollbar_height">
            <el-tree ref="treeSale" :expand-on-click-node="false" :check-on-click-node="true" :check-strictly="true" :data="saleEntityList" show-checkbox node-key="ID" :props="defaultProps" :default-checked-keys="defaultCheckedKeysSale" :default-expanded-keys="defaultExpandedKeysSale"></el-tree>
          </el-scrollbar>
        </el-tab-pane>
      </el-tabs>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm_add')" size="small" :loading="modalLoading">保 存</el-button>
      </span>
    </el-dialog>
    <!--查看弹出框-->
    <el-dialog :title="dialogTitle" :visible.sync="showDialogVisible" width="980px">
      <el-tabs v-model="activeName">
        <el-tab-pane label="基本信息" name="1">
          <el-form label-width="150px" class="demo-ruleForm packageShow" size="small">
            <el-row>
              <el-col :span="12">
                <el-form-item label="套餐卡名称：">
                  <span>{{ruleForm_add.Name}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="套餐卡别名：">
                  <span>{{ruleForm_add.Alias}}</span>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="套餐卡分类：">
                  <span>{{PackageCardCategory}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="售卖日期：">
                  <span>{{saleDate}}</span>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="是否上架：">
                  <span v-if="ruleForm_add.IsAllowSell">是</span>
                  <span v-else>否</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="是否可以赠送：">
                  <span v-if="ruleForm_add.IsAllowLargess">是</span>
                  <span v-else>否</span>
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item label="卡有效期：">
                  <span v-if="ruleForm_add.ValidType==3">永久有效</span>
                  <span v-else-if="ruleForm_add.ValidType==1">销售后生成{{ruleForm_add.ValidDay}}天</span>
                  <span v-else>消耗后生成{{ruleForm_add.ValidDay}}天</span>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="备注信息：">
                  <span>{{ruleForm_add.Remark}}</span>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="详细信息" name="2">
          <div class="dis_flex flex_x_center">
            <showGoodsDetail ref="ShowGoodsDetail" :Memo="ruleForm_add.Memo" :ImageList="ruleForm_add.ImageList" :OriginalText="ruleForm_add.OriginalText" :Price="ruleForm_add.Price" :Name="ruleForm_add.Name" :isShowOriginal="false" GoodsType="套餐卡">
              <el-row slot="info">
                <el-col :span="24" class="padlt_10 padrt_10" style="height:40px;line-height:40px">商品信息</el-col>
                <el-col :span="24" class="back_f8 pad_10">
                  <el-col :span="24" class="martp_5">有效期:
                    <span v-if="ruleForm_add.ValidType == 1">购买后 {{ruleForm_add.ValidDay}} 天内有效</span>
                    <span v-if="ruleForm_add.ValidType == 2">第一次使用后 {{ruleForm_add.ValidDay}} 天内有效</span>
                    <span v-if="ruleForm_add.ValidType == 3">永久有效</span>
                  </el-col>
                  <el-col :span="24" class="martp_5">套餐明细: 包含共 {{goodsRangeList.length}} 项商品 <span style="color:#FF8D66">查看明细</span></el-col>
                  <el-col :span="24" class="martp_5">套餐赠送明细: 包含共 {{goodsRangeLargessList.length}} 项商品 <span style="color:#FF8D66">查看明细</span></el-col>
                </el-col>
              </el-row>
            </showGoodsDetail>
          </div>
        </el-tab-pane>
        <el-tab-pane name="3" label="套餐卡明细">
          <el-row :gutter="20" class="marbm_10">
            <el-col :span="14">
              <el-input placeholder="输入商品名称搜索" size="small" v-model="filterGoodsRangeProjectName" clearable></el-input>
            </el-col>
            <el-col :span="10">
              <div style="text-align: right; padding: 13px 0px 0px 0px">
                <b>套餐卡总价 : {{ this.ruleForm_add.Price }}</b>
              </div>
            </el-col>
          </el-row>
          <el-table size="small" max-height="400" :data="
              goodsRangeList.filter(
                (data) =>
                  !filterGoodsRangeProjectName ||
                  data.Name.toLowerCase().includes(
                    filterGoodsRangeProjectName.toLowerCase()
                  )
              )
            ">
            <el-table-column prop="Name" label="商品名称" sortable></el-table-column>
            <el-table-column prop="Alias" label="商品类型" sortable></el-table-column>
            <el-table-column prop="OldPrice" label="销售价格"></el-table-column>
            <el-table-column prop="Price" label="套餐价格">
            </el-table-column>
            <el-table-column prop="Amount" label="数量">
            </el-table-column>
            <el-table-column prop="TotalPrice" label="总价">
            </el-table-column>
          </el-table>
        </el-tab-pane>

        <el-tab-pane label="套餐卡赠送明细" name="4">
          <el-row :gutter="20" class="marbm_10">
            <el-col :span="6">
              <el-input placeholder="输入商品名称搜索" size="small" v-model="filterGoodsRangeLargessProjectName" clearable></el-input>
            </el-col>
            <el-col :span="12">
            </el-col>
          </el-row>
          <el-table size="small" max-height="400" :data="
              goodsRangeLargessList.filter(
                (data) =>
                  !filterGoodsRangeLargessProjectName ||
                  data.Name.toLowerCase().includes(
                    filterGoodsRangeLargessProjectName.toLowerCase()
                  )
              )
            ">
            <el-table-column prop="Name" label="商品名称" sortable></el-table-column>
            <el-table-column prop="Alias" label="商品类型" sortable></el-table-column>
            <el-table-column prop="OldPrice" label="销售价格" sortable></el-table-column>
            <el-table-column label="数量/金额(储值卡填写赠送金额)">

              <template slot-scope="scope">
                <span v-if="scope.row.Alias == '储值卡'">{{scope.row.TotalPrice}}</span>
                <span v-else>{{scope.row.Amount}}</span>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
    <!--设置明细弹出框-->

    <el-dialog title="添加套餐卡商品" :visible.sync="dialogScope" width="900px">
      <div>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-collapse v-model="collapseIndex" accordion @change="collapseChange">
              <el-collapse-item title="产品" name="1">
                <el-input v-model="productName" placeholder="请输入关键词进行搜索" clearable size="small"></el-input>
                <el-scrollbar class="el-scrollbar_height_range">
                  <el-tree ref="treeProduct" :data="productList" node-key="PID" :props="{ children: 'Child', label: 'Name' }" :filter-node-method="filterNode" :default-checked-keys="productDefaultCheckedKeys" :default-expanded-keys="productDefaultExpandedKeys">
                    <div slot-scope="{ node, data }" style="width: 100%">
                      <el-row type="flex" justify="space-between">
                        <el-col :span="18">
                          <span>{{ data.Name }}</span>
                          <el-tag class="marlt_5" type="info" size="mini" v-if="data.IsGoods == 0">
                            分类
                          </el-tag>
                        </el-col>
                        <el-col :span="6" style="text-align: right">
                          <el-button type="text" @click="changeAll(1, data)" size="mini" v-if="data.IsGoods == 1">添加
                          </el-button>
                        </el-col>
                      </el-row>
                    </div>
                  </el-tree>
                </el-scrollbar>
              </el-collapse-item>
              <el-collapse-item title="项目" name="2">
                <el-input v-model="projectName" placeholder="请输入关键词进行搜索" clearable size="small"></el-input>
                <el-scrollbar class="el-scrollbar_height_range">
                  <el-tree ref="treeProject" :data="projectList" node-key="PID" :props="{ children: 'Child', label: 'Name' }" :filter-node-method="filterNode" :default-checked-keys="projectDefaultCheckedKeys" :default-expanded-keys="projectDefaultExpandedKeys">
                    <div slot-scope="{ node, data }" style="width: 100%">
                      <el-row type="flex" justify="space-between">
                        <el-col :span="18">
                          <span>{{ data.Name }}</span>
                          <el-tag class="marlt_5" type="info" size="mini" v-if="data.IsGoods == 0">
                            分类
                          </el-tag>
                        </el-col>
                        <el-col :span="6" style="text-align: right">
                          <el-button type="text" @click="changeAll(2, data)" size="mini" v-if="data.IsGoods == 1">添加
                          </el-button>
                        </el-col>
                      </el-row>
                    </div>
                  </el-tree>
                </el-scrollbar>
              </el-collapse-item>
              <el-collapse-item title="通用次卡" name="3">
                <el-input v-model="generalName" placeholder="请输入关键词进行搜索" clearable size="small"></el-input>
                <el-scrollbar class="el-scrollbar_height_range">
                  <el-tree ref="treeGeneral" :data="generalCardList" node-key="PID" :props="{ children: 'Child', label: 'Name' }" :filter-node-method="filterNode" :default-checked-keys="generalDefaultCheckedKeys" :default-expanded-keys="generalDefaultExpandedKeys">
                    <div slot-scope="{ node, data }" style="width: 100%">
                      <el-row type="flex" justify="space-between">
                        <el-col :span="18">
                          <span>{{ data.Name }}</span>
                          <el-tag class="marlt_5" type="info" size="mini" v-if="data.IsGoods == 0">
                            分类
                          </el-tag>
                        </el-col>
                        <el-col :span="6" style="text-align: right">
                          <el-button type="text" @click="changeAll(3, data)" size="mini" v-if="data.IsGoods == 1">添加
                          </el-button>
                        </el-col>
                      </el-row>
                    </div>
                  </el-tree>
                </el-scrollbar>
              </el-collapse-item>
              <el-collapse-item title="时效卡" name="4">
                <el-input v-model="timeName" placeholder="请输入关键词进行搜索" clearable size="small"></el-input>
                <el-scrollbar class="el-scrollbar_height_range">
                  <el-tree ref="treeTime" :data="timeCardList" node-key="PID" :props="{ children: 'Child', label: 'Name' }" :filter-node-method="filterNode" :default-checked-keys="timeDefaultCheckedKeys" :default-expanded-keys="timeDefaultExpandedKeys">
                    <div slot-scope="{ node, data }" style="width: 100%">
                      <el-row type="flex" justify="space-between">
                        <el-col :span="18">
                          <span>{{ data.Name }}</span>
                          <el-tag class="marlt_5" type="info" size="mini" v-if="data.IsGoods == 0">
                            分类
                          </el-tag>
                        </el-col>
                        <el-col :span="6" style="text-align: right">
                          <el-button type="text" @click="changeAll(4, data)" size="mini" v-if="data.IsGoods == 1">添加
                          </el-button>
                        </el-col>
                      </el-row>
                    </div>
                  </el-tree>
                </el-scrollbar>
              </el-collapse-item>
              <el-collapse-item title="储值卡" name="5">
                <el-input v-model="chuzhiKaName" placeholder="请输入关键词进行搜索" clearable size="small"></el-input>
                <el-scrollbar class="el-scrollbar_height_range">
                  <el-tree ref="treeChuzhiKa" :data="savingCardList" node-key="PID" :props="{ children: 'Child', label: 'Name' }" :filter-node-method="filterNode" :default-checked-keys="savingDefaultCheckedKeys" :default-expanded-keys="savingDefaultExpandedKeys">
                    <div slot-scope="{ node, data }" style="width: 100%">
                      <el-row type="flex" justify="space-between">
                        <el-col :span="18">
                          <span>{{ data.Name }}</span>
                          <el-tag class="marlt_5" type="info" size="mini" v-if="data.IsGoods == 0">
                            分类
                          </el-tag>
                        </el-col>
                        <el-col :span="6" style="text-align: right">
                          <el-button type="text" @click="changeAll(5, data)" size="mini" v-if="data.IsGoods == 1">添加
                          </el-button>
                        </el-col>
                      </el-row>
                    </div>
                  </el-tree>
                </el-scrollbar>
              </el-collapse-item>
            </el-collapse>
          </el-col>

          <el-col :span="16">
            <el-table size="small" :data="
                packageCardProduct.filter(
                  (data) =>
                    !productName ||
                    data.Name.toLowerCase().includes(productName.toLowerCase())
                )
              " v-if="collapseIndex == 1">
              <el-table-column prop="Name" label="产品名称">
                <template slot-scope="scope">
                  <span>{{ scope.row.Name }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="GoodsCategoryName" label="产品类型"></el-table-column>
              <el-table-column label="销售价格" prop="Price"></el-table-column>
              <el-table-column label="操作" width="80px">
                <template slot-scope="scope">
                  <el-button type="danger" size="small" @click="remove(3, scope.$index)">删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-table size="small" :data="
                packageCardProject.filter(
                  (data) =>
                    !projectName ||
                    data.Name.toLowerCase().includes(projectName.toLowerCase())
                )
              " v-if="collapseIndex == 2">
              <el-table-column prop="Name" label="项目名称">
                <template slot-scope="scope">
                  <span>{{ scope.row.Name }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="GoodsCategoryName" label="项目类型"></el-table-column>
              <el-table-column label="销售价格" prop="Price"></el-table-column>
              <el-table-column label="操作" width="80px">
                <template slot-scope="scope">
                  <el-button type="danger" size="small" @click="remove(4, scope.$index)">删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-table size="small" :data="
                packageCardGeneral.filter(
                  (data) =>
                    !generalName ||
                    data.Name.toLowerCase().includes(generalName.toLowerCase())
                )
              " v-if="collapseIndex == 3">
              <el-table-column prop="Name" label="通用次卡名称">
                <template slot-scope="scope">
                  <span>{{ scope.row.Name }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="GoodsCategoryName" label="通用次卡类型"></el-table-column>
              <el-table-column label="售价" prop="Price"></el-table-column>
              <el-table-column label="操作" width="80px">
                <template slot-scope="scope">
                  <el-button type="danger" size="small" @click="remove(5, scope.$index)">删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-table size="small" :data="
                packageCardTime.filter(
                  (data) =>
                    !timeName ||
                    data.Name.toLowerCase().includes(timeName.toLowerCase())
                )
              " v-if="collapseIndex == 4">
              <el-table-column prop="Name" label="时效卡名称">
                <template slot-scope="scope">
                  <span>{{ scope.row.Name }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="GoodsCategoryName" label="时效卡类型"></el-table-column>
              <el-table-column label="售价" prop="Price"></el-table-column>
              <el-table-column label="操作" width="80px">
                <template slot-scope="scope">
                  <el-button type="danger" size="small" @click="remove(6, scope.$index)">删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-table size="small" :data="
                packageCardSaving.filter(
                  (data) =>
                    !chuzhiKaName ||
                    data.Name.toLowerCase().includes(chuzhiKaName.toLowerCase())
                )
              " v-if="collapseIndex == 5">
              <el-table-column prop="Name" label="储值卡名称">
                <template slot-scope="scope">
                  <span>{{ scope.row.Name }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="GoodsCategoryName" label="储值卡类型"></el-table-column>
              <el-table-column label="售价" prop="Price"></el-table-column>
              <el-table-column label="操作" width="80px">
                <template slot-scope="scope">
                  <el-button type="danger" size="small" @click="remove(7, scope.$index)">删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>

            <!--展示全部-->
            <el-table size="small" max-height="500px" :data="packageCardSelectAll" v-if="collapseIndex == ''">
              <el-table-column prop="Name" label="商品名称"></el-table-column>
              <el-table-column prop="Alias" label="商品类型" width="100px"></el-table-column>
              <el-table-column prop="OldPrice" label="销售价格"></el-table-column>
              <el-table-column label="操作" width="80px">
                <template slot-scope="scope">
                  <el-button type="danger" size="small" @click="remove(8, scope.row, scope.$index)">删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogScope = false">取 消</el-button>
        <el-button size="small" type="primary" @click="addProjectSelect">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
var Enumerable = require("linq");
import API from "@/api/iBeauty/Goods/packageCard";
import APIEntity from "@/api/KHS/Entity/entity";
import APICategory from "@/api/iBeauty/Basic/packageCardCategory";
import APISaving from "@/api/iBeauty/Goods/savingCard";
import permission from "@/components/js/permission.js";
import goodsDetail from "../Goods/Components/GoodsDetail.vue";
import utils from "@/components/js/utils.js";
import showGoodsDetail from "../Goods/Components/ShowGoodsDetail.vue";

export default {
  name: "PackageCard",
  /**  引入的组件  */
  components: {
    goodsDetail,
    showGoodsDetail,
  },
  data() {
    return {
      loading: false,
      modalLoading: false,
      dialogVisible: false, // 增加、编辑弹出框状态
      showDialogVisible: false, //查看弹出层
      dialogTitle: "", // 增加、编辑弹出框标题,
      activeName: "1",
      Name: "",
      PackageCardCategoryID: "",
      isAllowSell: true,
      tableData: [],
      collapseIndex: "", //卡项
      entityList: [], //销售范围
      defaultCheckedKeysSale: [], //销售范围
      defaultExpandedKeysSale: [1],
      saleEntityList: [],
      category: [],
      productName: "",
      productList: [], //产品列表
      productDefaultCheckedKeys: [], //回显
      productDefaultExpandedKeys: [1],
      projectName: "",
      projectList: [], //项目
      projectDefaultCheckedKeys: [], //回显
      projectDefaultExpandedKeys: [1],
      timeName: "",
      timeCardList: [], //时效卡
      timeDefaultCheckedKeys: [], //回显
      timeDefaultExpandedKeys: [1],
      generalName: "",
      generalCardList: [], //通用次卡
      generalDefaultCheckedKeys: [], //回显
      generalDefaultExpandedKeys: [1],
      chuzhiKaName: "",
      savingCardList: [], //储值卡
      savingDefaultCheckedKeys: [], //回显
      savingDefaultExpandedKeys: [1],
      packageCardProject: [],
      packageCardGeneral: [],
      packageCardProduct: [], //保存的产品
      packageCardTime: [],
      packageCardSaving: [],
      packageCardSelectAll: [],

      isAdd: true,
      isLargess: 1, // 1 明细  2赠额范围,
      dialogScope: false,

      filterGoodsRangeProjectName: "", //本金项目过滤
      goodsRangeList: [],
      goodsRangeProductList: [],
      goodsRangeProjectList: [],
      goodsRangeGeneralCardList: [],
      goodsRangeTimeCardList: [],
      goodsRangeSavingCardCardList: [],
      filterGoodsRangeLargessProjectName: "", //赠金项目过滤
      goodsRangeLargessList: [],
      goodsRangeLargessProductList: [],
      goodsRangeLargessProjectList: [],
      goodsRangeLargessGeneralCardList: [],
      goodsRangeLargessTimeCardList: [],
      goodsRangeLargessSavingCardList: [],
      // 新增编辑弹出框表单数据
      ruleForm_add: {
        Name: "", //套餐卡名称
        Alias: "",
        IsAllowLargess: true,
        IsAllowSell: true,
        PackageCardCategoryID: "",
        PackageCardCategoryName: "",
        Price: "",
        SaleEndingDate: "",
        SaleStartDate: "",
        ValidDay: "",
        ValidType: 3,
        Goods: {
          Product: [],
          Project: [],
          GeneralCard: [],
          TimeCard: [],
        },
        GoodsLargess: {
          Product: [],
          Project: [],
          GeneralCard: [],
          TimeCard: [],
        },
        saleDate: [],
        PackageCardSaleEntity: [],
        ID: "",
        Remark: "",
        Memo: "",
        ImageList: [],
      },
      rules_add: {
        Name: [
          { required: true, message: "请输入套餐卡名称", trigger: "blur" },
        ],
        PackageCardCategoryID: [
          { required: true, message: "请选择套餐卡分类", trigger: "blur" },
        ],
        Price: [{ required: true, message: "请输入销售价格", trigger: "blur" }],
        saleDate: [
          { required: true, message: "请选择售卖日期", trigger: "change" },
        ],
        ValidType: [
          {
            required: true,
            message: "请选择套餐卡有效类型",
            trigger: "change",
          },
        ],
        ValidDay: [
          {
            type: "date",
            required: true,
            message: "请选择套餐卡有效时间",
            trigger: "change",
          },
        ],
        IsAllowSell: [
          { required: true, message: "请选择是否上架", trigger: "change" },
        ],
        IsAllowLargess: [
          { required: true, message: "请选择是否可以赠送", trigger: "change" },
        ],
      },
      defaultProps: {
        children: "Child",
        label: "EntityName",
      },
      //需要给分页组件传的信息
      paginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
      isPackageCardEdit: "", //编辑权限
      isPackageCardNew: "", //新增权限
    };
  },
  computed: {
    PackageCardCategory: function () {
      if (!this.ruleForm_add.PackageCardCategoryID) return "";
      const value = this.ruleForm_add.PackageCardCategoryID.toString();
      const item = this.category.find((item) => item.ID == value);
      return item.Name;
    },
    saleDate: function () {
      return (
        this.ruleForm_add.SaleStartDate +
        " " +
        "至" +
        " " +
        this.ruleForm_add.SaleEndingDate
      );
    },
  },
  watch: {
    productName(val) {
      this.$refs.treeProduct.filter(val);
    },
    projectName(val) {
      this.$refs.treeProject.filter(val);
    },
    generalName(val) {
      this.$refs.treeGeneral.filter(val);
    },
    timeName(val) {
      this.$refs.treeTime.filter(val);
    },
    chuzhiKaName(val) {
      this.$refs.treeChuzhiKa.filter(val);
    },
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.Name.indexOf(value) !== -1;
    },
    // 数据显示
    handleSearch: function () {
      let that = this;
      that.paginations.page = 1;
      that.search();
    },

    remove(index, rowindex, indexall) {
      var that = this;
      if (index === 1) {
        for (let i = 0; i < this.goodsRangeList.length; i++) {
          if (
            this.goodsRangeList[i].Alias == rowindex.Alias &&
            this.goodsRangeList[i].PID == rowindex.PID &&
            this.goodsRangeList[i].Amount == rowindex.Amount &&
            this.goodsRangeList[i].Price == rowindex.Price
          ) {
            that.goodsRangeList.splice(i, 1);
            this.allPrice();
            return;
          }
        }
      } else if (index == 2) {
        for (let i = 0; i < this.goodsRangeLargessList.length; i++) {
          if (
            this.goodsRangeLargessList[i].Alias == rowindex.Alias &&
            this.goodsRangeLargessList[i].PID == rowindex.PID &&
            this.goodsRangeLargessList[i].Amount == rowindex.Amount &&
            this.goodsRangeLargessList[i].Price == rowindex.Price
          ) {
            that.goodsRangeLargessList.splice(i, 1);
            return;
          }
        }
      } else if (index == 3) {
        this.packageCardProduct.splice(rowindex, 1);
      } else if (index == 4) {
        this.packageCardProject.splice(rowindex, 1);
      } else if (index == 5) {
        this.packageCardGeneral.splice(rowindex, 1);
      } else if (index == 6) {
        this.packageCardTime.splice(rowindex, 1);
      } else if (index == 7) {
        this.packageCardSaving.splice(rowindex, 1);
      } else {
        if (rowindex.Alias == "产品") {
          this.packageCardProduct.splice(
            this.packageCardProduct.findIndex((p) => p.PID == rowindex.PID),
            1
          );
        } else if (rowindex.Alias == "项目") {
          this.packageCardProject.splice(
            this.packageCardProject.findIndex((p) => p.PID == rowindex.PID),
            1
          );
        } else if (rowindex.Alias == "通用次卡") {
          this.packageCardGeneral.splice(
            this.packageCardGeneral.findIndex((p) => p.PID == rowindex.PID),
            1
          );
        } else if (rowindex.Alias == "时效卡") {
          this.packageCardTime.splice(
            this.packageCardTime.findIndex((p) => p.PID == rowindex.PID),
            1
          );
        } else {
          this.packageCardSaving.splice(
            this.packageCardSaving.findIndex((p) => p.PID == rowindex.PID),
            1
          );
        }
        this.packageCardSelectAll.splice(indexall, 1);
      }
      this.collapseChange();
    },
    collapseChange: function () {
      var that = this;
      that.packageCardSelectAll = that.packageCardProduct.concat(
        that.packageCardProject,
        that.packageCardGeneral,
        that.packageCardTime,
        that.packageCardSaving
      );
    },
    //设置明细中选择的产品、项目...
    changeAll(index, data) {
      if (index === 1) {
        this.packageCardProduct.push({
          PID: data.PID,
          ID: data.ID,
          Amount: "",
          OldPrice: data.Price,
          Price: data.Price,
          GoodsCategoryName: data.GoodsCategoryName,
          TotalPrice: "",
          Alias: "产品",
          Name: data.Name,
        });
      } else if (index === 2) {
        this.packageCardProject.push({
          PID: data.PID,
          ID: data.ID,
          Amount: "",
          OldPrice: data.Price,
          Price: data.Price,
          GoodsCategoryName: data.GoodsCategoryName,
          TotalPrice: "",
          Alias: "项目",
          Name: data.Name,
        });
      } else if (index === 3) {
        this.packageCardGeneral.push({
          PID: data.PID,
          ID: data.ID,
          Amount: "",
          OldPrice: data.Price,
          Price: data.Price,
          GoodsCategoryName: data.GoodsCategoryName,
          TotalPrice: "",
          Alias: "通用次卡",
          Name: data.Name,
        });
      } else if (index === 4) {
        this.packageCardTime.push({
          PID: data.PID,
          ID: data.ID,
          Amount: "",
          OldPrice: data.Price,
          Price: data.Price,
          GoodsCategoryName: data.GoodsCategoryName,
          TotalPrice: "",
          Alias: "时效卡",
          Name: data.Name,
        });
      } else {
        this.packageCardSaving.push({
          PID: data.PID,
          ID: data.ID,
          Amount: "",
          OldPrice: data.Price,
          Price: data.Price,
          GoodsCategoryName: data.GoodsCategoryName,
          TotalPrice: "",
          Alias: "储值卡",
          Name: data.Name,
        });
      }
      this.packageCardSelectAll = this.packageCardProduct.concat(
        this.packageCardProject,
        this.packageCardGeneral,
        this.packageCardTime,
        this.packageCardSaving
      );
    },
    // 搜索
    search: function () {
      var that = this;
      let params = {
        PageNum: that.paginations.page,
        Name: that.Name,
        PackageCardCategoryID: that.PackageCardCategoryID,
        IsAllowSell: that.isAllowSell,
      };
      that.loading = true;
      API.packageCardList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.tableData = res.List;
            that.paginations.total = res.Total;
            that.paginations.page_size = res.PageSize;
          } else {
            that.$message.error({
              duration: 3000,
              message: res.message,
            });
          }
        })
        .finally(() => {
          that.loading = false;
        });
    },
    // 上下分页
    handleCurrentChange(page) {
      this.paginations.page = page;
      this.search();
    },
    //套餐卡分类数据
    categoryData: function () {
      var that = this;
      var params = {
        Name: "",
        Active: true,
      };
      APICategory.getPackageCardCategory(params).then((res) => {
        if (res.StateCode == 200) {
          that.category = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    //获取销售范围
    entityData: function () {
      var that = this;
      that.loading = true;
      var params = {
        SearchKey: "",
        Active: true,
      };
      APIEntity.getEntity(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.entityList = res.Data;
          } else {
            this.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    //获取明细范围、或者增额范围
    getEditDetail(IsLargess) {
      var that = this;
      let params = {
        ID: this.ruleForm_add.ID,
        IsLargess: IsLargess,
      };
      API.packageCardGoods(params).then((res) => {
        if (res.StateCode == 200) {
          if (res.Data.Product.length > 0) {
            res.Data.Product = Enumerable.from(res.Data.Product)
              .select((val) => ({
                PID: "1" + val.ID,
                ID: val.ID,
                Amount: val.Amount,
                OldPrice: val.OriginalPrice,
                Price: val.Price,
                TotalPrice: val.TotalPrice,
                Name: val.Name,
                Alias: "产品",
              }))
              .toArray();
          }
          if (res.Data.Project.length > 0) {
            res.Data.Project = Enumerable.from(res.Data.Project)
              .select((val) => ({
                PID: "1" + val.ID,
                ID: val.ID,
                isGoods: 1,
                Amount: val.Amount,
                OldPrice: val.OriginalPrice,
                Price: val.Price,
                TotalPrice: val.TotalPrice,
                Name: val.Name,
                Alias: "项目",
              }))
              .toArray();
          }
          if (res.Data.GeneralCard.length > 0) {
            res.Data.GeneralCard = Enumerable.from(res.Data.GeneralCard)
              .select((val) => ({
                PID: "1" + val.ID,
                ID: val.ID,
                Amount: val.Amount,
                Price: val.Price,
                OldPrice: val.OriginalPrice,
                TotalPrice: val.TotalPrice,
                Name: val.Name,
                Alias: "通用次卡",
              }))
              .toArray();
          }
          if (res.Data.TimeCard.length > 0) {
            res.Data.TimeCard = Enumerable.from(res.Data.TimeCard)
              .select((val) => ({
                PID: "1" + val.ID,
                isGoods: 1,
                ID: val.ID,
                Amount: val.Amount,
                OldPrice: val.OriginalPrice,
                Price: val.Price,
                TotalPrice: val.TotalPrice,
                Name: val.Name,
                Alias: "时效卡",
              }))
              .toArray();
          }
          if (res.Data.SavingCard.length > 0) {
            res.Data.SavingCard = Enumerable.from(res.Data.SavingCard)
              .select((val) => ({
                PID: "1" + val.ID,
                isGoods: 1,
                ID: val.ID,
                Amount: val.Amount,
                OldPrice: val.OriginalPrice,
                Price: val.Price,
                TotalPrice: val.TotalPrice,
                Name: val.Name,
                Alias: "储值卡",
              }))
              .toArray();
          }
          if (!IsLargess) {
            that.ruleForm_add.Goods = {
              Product: res.Data.Product,
              Project: res.Data.Project,
              GeneralCard: res.Data.GeneralCard,
              TimeCard: res.Data.TimeCard,
              SavingCard: res.Data.SavingCard,
            };
            that.goodsRangeProductList = Object.assign([], res.Data.Product);
            that.goodsRangeProjectList = Object.assign([], res.Data.Project);
            that.goodsRangeGeneralCardList = Object.assign(
              [],
              res.Data.GeneralCard
            );
            that.goodsRangeTimeCardList = Object.assign([], res.Data.TimeCard);
            that.goodsRangeSavingCardCardList = Object.assign(
              [],
              res.Data.SavingCard
            );
            that.goodsRangeList = res.Data.Project.concat(
              res.Data.SavingCard,
              res.Data.GeneralCard,
              res.Data.TimeCard,
              res.Data.Product
            );
          } else {
            that.ruleForm_add.GoodsLargess = {
              Product: res.Data.Product,
              Project: res.Data.Project,
              GeneralCard: res.Data.GeneralCard,
              TimeCard: res.Data.TimeCard,
              SavingCard: res.Data.SavingCard,
            };
            that.goodsRangeLargessProductList = Object.assign(
              [],
              res.Data.Product
            );
            that.goodsRangeLargessProjectList = Object.assign(
              [],
              res.Data.Project
            );
            that.goodsRangeLargessGeneralCardList = Object.assign(
              [],
              res.Data.GeneralCard
            );
            that.goodsRangeLargessTimeCardList = Object.assign(
              [],
              res.Data.TimeCard
            );
            that.goodsRangeLargessSavingCardList = Object.assign(
              [],
              res.Data.SavingCard
            );
            that.goodsRangeLargessList = res.Data.Project.concat(
              res.Data.SavingCard,
              res.Data.GeneralCard,
              res.Data.TimeCard,
              res.Data.Product
            );
          }
        } else {
          this.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    //获取套餐卡销售范围
    getPackageCardSaleEntity: function (row) {
      var that = this;
      that.loading = true;
      var params = {
        ID: row.ID,
      };
      API.packageCardSaleEntity(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.defaultCheckedKeysSale = res.Data;
            that.defaultExpandedKeysSale = res.Data;
          } else {
            this.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 获取产品
    productData: function () {
      var that = this;
      let params = {
        productName: that.productName,
      };
      APISaving.savingCardProduct(params).then((res) => {
        if (res.StateCode == 200) {
          this.productList = res.Data;
          that.setRecursion(res.Data);
        } else {
          this.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },

    // 获取项目
    projectData: function () {
      var that = this;
      APISaving.savingCardProject().then((res) => {
        if (res.StateCode == 200) {
          that.projectList = res.Data;
          that.setRecursion(res.Data);
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 获取通用次卡
    generalCardData: function () {
      var that = this;
      APISaving.savingCardGeneralCard().then((res) => {
        if (res.StateCode == 200) {
          that.generalCardList = res.Data;
          that.setRecursion(res.Data);
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 获取储值卡列表
    savingCardData: function () {
      var that = this;
      APISaving.savingCard().then((res) => {
        if (res.StateCode == 200) {
          that.savingCardList = res.Data;
          that.setRecursion(res.Data);
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 获取时效卡
    timeCardData: function () {
      var that = this;
      APISaving.savingCardTimeCard().then((res) => {
        if (res.StateCode == 200) {
          that.timeCardList = res.Data;
          that.setRecursion(res.Data);
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 递归
    setRecursion(data) {
      var that = this;
      for (let i = 0; i <= data.length - 1; i++) {
        if (data[i].IsGoods == 0) {
          data[i].PID = "0" + data[i].ID;
        } else {
          data[i].PID = "1" + data[i].ID;
        }
        if (data[i].Child) {
          that.setRecursion(data[i].Child);
        }
      }
    },
    // 新增
    showAddDialog: function () {
      this.dialogVisible = true;
      this.dialogTitle = "新增套餐卡";
      this.isAdd = true;
      this.activeName = "1";
      this.ruleForm_add = {
        Alias: "",
        Name: "",
        PackageCardCategoryID: "",
        SaleEndingDate: "",
        SaleStartDate: "",
        ValidType: 3,
        ValidDay: "",
        Price: "",
        IsAllowLargess: false,
        IsAllowSell: true,
        PackageCardSaleEntity: [],
        saleDate: [],
        Goods: {
          Product: [],
          Project: [],
          GeneralCard: [],
          TimeCard: [],
        },
        GoodsLargess: {
          Product: [],
          Project: [],
          GeneralCard: [],
          TimeCard: [],
        },
        Memo: "",
        ImageList: [],
      };
      this.defaultCheckedKeysSale = [];
      this.defaultExpandedKeysSale = [1];
      this.filterGoodsRangeProjectName = ""; //本金项目过滤
      this.filterGoodsRangeLargessProjectName = ""; //赠金项目过滤
      this.goodsRangeList = [];
      this.goodsRangeLargessList = [];
      this.saleEntityList = [];
      Object.assign(this.saleEntityList, this.entityList);
      this.getGoodsDataNewWork();

      this.dialogVisible = true;
    },
    // 编辑
    showEditDialog: function (row, type) {
      this.isAdd = false;
      this.ruleForm_add.ID = row.ID;
      this.activeName = "1";
      this.ruleForm_add = Object.assign({}, row);
      this.$set(this.ruleForm_add, "saleDate", [
        new Date(row.SaleStartDate),
        new Date(row.SaleEndingDate),
      ]);
      this.ruleForm_add.Goods = {
        Product: [],
        Project: [],
        GeneralCard: [],
        TimeCard: [],
        SavingCard: [],
      };
      this.ruleForm_add.GoodsLargess = {
        Product: [],
        Project: [],
        GeneralCard: [],
        TimeCard: [],
        SavingCard: [],
      };

      this.getEditDetail(true);
      this.getEditDetail(false);

      this.saleEntityList = [];
      this.defaultCheckedKeysSale = [];
      this.defaultExpandedKeysSale = [1];
      this.getPackageCardSaleEntity(row);
      Object.assign(this.saleEntityList, this.entityList);
      this.filterGoodsRangeProjectName = ""; //本金项目过滤
      this.filterGoodsRangeLargessProjectName = ""; //赠金项目过滤

      this.getGoodsDataNewWork();
      this.getPackageCardDetails(this.ruleForm_add.ID);

      this.dialogVisible = type == "edit" ? true : false;
      this.showDialogVisible = type == "show" ? true : false;
      this.dialogTitle = type == "edit" ? "编辑套餐卡" : "套餐卡详情";
    },
    // 适用项目
    addProjectDialog: function (index) {
      var that = this;
      that.isLargess = index;
      that.productList = Object.assign([], that.productList);
      that.projectList = Object.assign([], that.projectList);
      that.generalCardList = Object.assign([], that.generalCardList);
      that.timeCardList = Object.assign([], that.timeCardList);
      that.savingCardList = Object.assign([], that.savingCardList);
      that.packageCardProduct = []; //选中适用产品
      that.packageCardProject = []; //选中适用项目
      that.packageCardGeneral = []; //选中适用通用次卡
      that.packageCardTime = []; //选中适用项目
      that.savingCardPackage = []; //选中适用项目
      that.packageCardSaving = [];
      that.packageCardSelectAll = [];
      that.productDefaultCheckedKeys = []; //回显
      that.productDefaultExpandedKeys = [1];
      that.projectDefaultCheckedKeys = []; //回显
      that.projectDefaultExpandedKeys = [1];
      that.generalDefaultCheckedKeys = []; //回显
      that.generalDefaultExpandedKeys = [1];
      that.timeDefaultCheckedKeys = []; //回显
      that.timeDefaultExpandedKeys = [1];
      that.packageDefaultCheckedKeys = []; //回显
      that.packageDefaultExpandedKeys = [1];

      if (index == 1) {
        that.goodsRangeProductList = Enumerable.from(that.goodsRangeList)
          .where(function (i) {
            return i.Alias == "产品";
          })
          .toArray();
        that.goodsRangeProjectList = Enumerable.from(that.goodsRangeList)
          .where(function (i) {
            return i.Alias == "项目";
          })
          .toArray();
        that.goodsRangeGeneralCardList = Enumerable.from(that.goodsRangeList)
          .where(function (i) {
            return i.Alias == "通用次卡";
          })
          .toArray();
        that.goodsRangeTimeCardList = Enumerable.from(that.goodsRangeList)
          .where(function (i) {
            return i.Alias == "时效卡";
          })
          .toArray();
        that.goodsRangeSavingCardCardList = Enumerable.from(that.goodsRangeList)
          .where(function (i) {
            return i.Alias == "储值卡";
          })
          .toArray();
        Object.assign(that.packageCardProduct, that.goodsRangeProductList);
        Object.assign(that.packageCardProject, that.goodsRangeProjectList);
        Object.assign(that.packageCardGeneral, that.goodsRangeGeneralCardList);
        Object.assign(that.packageCardTime, that.goodsRangeTimeCardList);
        Object.assign(
          that.packageCardSaving,
          that.goodsRangeSavingCardCardList
        );
        that.packageCardSelectAll = that.goodsRangeProductList.concat(
          that.goodsRangeProjectList,
          that.goodsRangeGeneralCardList,
          that.goodsRangeTimeCardList,
          that.goodsRangeSavingCardCardList
        );
      } else {
        that.goodsRangeLargessProductList = Enumerable.from(
          that.goodsRangeLargessList
        )
          .where(function (i) {
            return i.Alias == "产品";
          })
          .toArray();
        that.goodsRangeLargessProjectList = Enumerable.from(
          that.goodsRangeLargessList
        )
          .where(function (i) {
            return i.Alias == "项目";
          })
          .toArray();
        that.goodsRangeLargessGeneralCardList = Enumerable.from(
          that.goodsRangeLargessList
        )
          .where(function (i) {
            return i.Alias == "通用次卡";
          })
          .toArray();
        that.goodsRangeLargessTimeCardList = Enumerable.from(
          that.goodsRangeLargessList
        )
          .where(function (i) {
            return i.Alias == "时效卡";
          })
          .toArray();
        that.goodsRangeLargessSavingCardList = Enumerable.from(
          that.goodsRangeLargessList
        )
          .where(function (i) {
            return i.Alias == "储值卡";
          })
          .toArray();
        Object.assign(
          that.packageCardProduct,
          that.goodsRangeLargessProductList
        );
        Object.assign(
          that.packageCardProject,
          that.goodsRangeLargessProjectList
        );
        Object.assign(
          that.packageCardGeneral,
          that.goodsRangeLargessGeneralCardList
        );
        Object.assign(that.packageCardTime, that.goodsRangeLargessTimeCardList);
        Object.assign(
          that.packageCardSaving,
          that.goodsRangeLargessSavingCardList
        );
        that.packageCardSelectAll = that.goodsRangeLargessProductList.concat(
          that.goodsRangeLargessProjectList,
          that.goodsRangeLargessGeneralCardList,
          that.goodsRangeLargessTimeCardList,
          that.goodsRangeLargessSavingCardList
        );
      }
      that.dialogScope = true;
    },
    // 确认选中适用项目
    addProjectSelect: function () {
      var that = this;
      if (that.isLargess == 1) {
        that.goodsRangeProductList = that.packageCardProduct; //产品集合
        that.goodsRangeProjectList = that.packageCardProject; //项目集合
        that.goodsRangeGeneralCardList = that.packageCardGeneral; //通用次卡集合
        that.goodsRangeTimeCardList = that.packageCardTime; //时效卡集合
        that.goodsRangeSavingCardCardList = that.packageCardSaving; //储值卡集合
        that.goodsRangeList = that.goodsRangeProductList.concat(
          that.goodsRangeProjectList,
          that.goodsRangeGeneralCardList,
          that.goodsRangeTimeCardList,
          that.goodsRangeSavingCardCardList
        );
      } else {
        that.goodsRangeLargessProductList = that.packageCardProduct; //产品集合
        that.goodsRangeLargessProjectList = that.packageCardProject; //项目集合
        that.goodsRangeLargessGeneralCardList = that.packageCardGeneral; //通用次卡集合
        that.goodsRangeLargessTimeCardList = that.packageCardTime; //时效卡集合
        that.goodsRangeLargessSavingCardList = that.packageCardSaving; //储值卡集合
        that.goodsRangeLargessList = that.goodsRangeLargessProductList.concat(
          that.goodsRangeLargessProjectList,
          that.goodsRangeLargessGeneralCardList,
          that.goodsRangeLargessTimeCardList,
          that.goodsRangeLargessSavingCardList
        );
      }
      that.dialogScope = false;
    },
    //赠送明细改变数量计算总价
    zengsongChange(row) {
      if (row.Alias == "储值卡") {
        if (row.TotalPrice) {
          row.Amount = 1;
        }
      } else {
        if (row.Price != "" && row.Amount) {
          let totalPrice = (
            parseFloat(row.Price) * parseFloat(row.Amount)
          ).toFixed(2);
          this.$set(row, "TotalPrice", totalPrice);
        }
      }
    },
    //数量改变
    Componte(row) {
      if (row.Price != "" && row.Amount) {
        let totalPrice = (
          parseFloat(row.Price) * parseFloat(row.Amount)
        ).toFixed(2);
        this.$set(row, "TotalPrice", totalPrice);
        this.allPrice();
      }
    },
    //套餐价改变
    taocanPrice(row) {
      if (row.Price != "" && row.Amount != "") {
        let totalPrice = (
          parseFloat(row.Price) * parseFloat(row.Amount)
        ).toFixed(2);
        this.$set(row, "TotalPrice", totalPrice);
        this.allPrice();
      }
    },
    //总价改变
    jisuanPrice(row) {
      if (row.Price != "" && row.Amount != "" && row.TotalPrice != "") {
        let price = (
          parseFloat(row.TotalPrice) / parseFloat(row.Amount)
        ).toFixed(2);
        this.$set(row, "Price", price);
        this.allPrice();
      }
    },
    //套餐卡总金额
    allPrice() {
      var allTotalPrice = 0;
      this.goodsRangeList.forEach((p) => {
        if (p.Price != "" && p.Amount != "") {
          allTotalPrice = (
            parseFloat(allTotalPrice) + parseFloat(p.TotalPrice)
          ).toFixed(2);
          this.ruleForm_add.Price = allTotalPrice;
        }
      });
      if (this.goodsRangeList.length == 0) {
        this.ruleForm_add.Price = 0;
      }
    },
    // 表单确认事件
    submitForm(ruleForm_add) {
      var that = this;
      this.$refs[ruleForm_add].validate((valid) => {
        if (valid) {
          if (
            that.ruleForm_add.ValidType != 3 &&
            that.ruleForm_add.ValidDay == ""
          ) {
            that.$message.error({
              message: "请输入有效期",
              duration: 2000,
            });
            return false;
          }
          let para = Object.assign({}, this.ruleForm_add);

          para.Memo = that.$refs["goodsDetail"].getMemo();
          para.ImageList = Enumerable.from(that.ruleForm_add.ImageList)
            .select((val) => ({ ImageUrl: val.url }))
            .toArray();
          var projectDD = Enumerable.from(this.goodsRangeList)
            .where(function (i) {
              return i.Alias == "项目";
            })
            .select((val) => ({
              ID: val.ID,
              Amount: val.Amount,
              Price: val.Price,
              TotalPrice: val.TotalPrice,
            }))
            .toArray();
          var productDD = Enumerable.from(this.goodsRangeList)
            .where(function (i) {
              return i.Alias == "产品";
            })
            .select((val) => ({
              ID: val.ID,
              Amount: val.Amount,
              Price: val.Price,
              TotalPrice: val.TotalPrice,
            }))
            .toArray();
          var generalDD = Enumerable.from(this.goodsRangeList)
            .where(function (i) {
              return i.Alias == "通用次卡";
            })
            .select((val) => ({
              ID: val.ID,
              Amount: val.Amount,
              Price: val.Price,
              TotalPrice: val.TotalPrice,
            }))
            .toArray();
          var timeDD = Enumerable.from(this.goodsRangeList)
            .where(function (i) {
              return i.Alias == "时效卡";
            })
            .select((val) => ({
              ID: val.ID,
              Amount: val.Amount,
              Price: val.Price,
              TotalPrice: val.TotalPrice,
            }))
            .toArray();

          var chuzhiKaDD = Enumerable.from(this.goodsRangeList)
            .where(function (i) {
              return i.Alias == "储值卡";
            })
            .select((val) => ({
              ID: val.ID,
              Amount: val.Amount,
              Price: val.Price,
              TotalPrice: val.TotalPrice,
            }))
            .toArray();

          var projectDDLargess = Enumerable.from(this.goodsRangeLargessList)
            .where(function (i) {
              return i.Alias == "项目";
            })
            .select((val) => ({
              ID: val.ID,
              Amount: val.Amount,
              Price: val.Price,
              TotalPrice: val.TotalPrice,
            }))
            .toArray();
          var productDDLargess = Enumerable.from(this.goodsRangeLargessList)
            .where(function (i) {
              return i.Alias == "产品";
            })
            .select((val) => ({
              ID: val.ID,
              Amount: val.Amount,
              Price: val.Price,
              TotalPrice: val.TotalPrice,
            }))
            .toArray();
          var generalDDLargess = Enumerable.from(this.goodsRangeLargessList)
            .where(function (i) {
              return i.Alias == "通用次卡";
            })
            .select((val) => ({
              ID: val.ID,
              Amount: val.Amount,
              Price: val.Price,
              TotalPrice: val.TotalPrice,
            }))
            .toArray();
          var timeDDLargess = Enumerable.from(this.goodsRangeLargessList)
            .where(function (i) {
              return i.Alias == "时效卡";
            })
            .select((val) => ({
              ID: val.ID,
              Amount: val.Amount,
              Price: val.Price,
              TotalPrice: val.TotalPrice,
            }))
            .toArray();
          var chuzhikaDDLargess = Enumerable.from(this.goodsRangeLargessList)
            .where(function (i) {
              return i.Alias == "储值卡";
            })
            .select((val) => ({
              ID: val.ID,
              Amount: val.Amount,
              Price: val.Price,
              TotalPrice: val.TotalPrice,
            }))
            .toArray();

          para.Goods.Product = productDD;
          para.Goods.Project = projectDD;
          para.Goods.GeneralCard = generalDD;
          para.Goods.TimeCard = timeDD;
          para.Goods.SavingCard = chuzhiKaDD;
          para.GoodsLargess.Product = productDDLargess;
          para.GoodsLargess.Project = projectDDLargess;
          para.GoodsLargess.GeneralCard = generalDDLargess;
          para.GoodsLargess.TimeCard = timeDDLargess;
          para.GoodsLargess.SavingCard = chuzhikaDDLargess;
          para.SaleStartDate = para.saleDate[0];
          para.SaleEndingDate = para.saleDate[1];
          para.PackageCardSaleEntity = this.$refs.treeSale.getCheckedKeys();
          delete para.saleDate;
          delete para.PackageCardCategoryName;
          var filterGoods = [];
          var filterGoodsLargess = [];
          var panduan1 = filterGoods.concat(
            projectDD,
            productDD,
            generalDD,
            timeDD,
            chuzhiKaDD
          );
          var panduan2 = filterGoodsLargess.concat(
            projectDDLargess,
            productDDLargess,
            generalDDLargess,
            timeDDLargess,
            chuzhikaDDLargess
          );
          if (panduan1.length > 0) {
            var savingCardPackage1 = Enumerable.from(panduan1)
              .where(function (i) {
                return i.TotalPrice == "" || i.Price == "" || i.Amount == "";
              })
              .toArray();
            if (savingCardPackage1.length > 0) {
              this.$message.error({
                duration: 3000,
                message: "明细范围下套餐价、数量、总价不能为空",
              });
              return;
            }
          }
          if (panduan2.length > 0) {
            var savingCardPackage2 = Enumerable.from(panduan2)
              .where(function (i) {
                return i.Amount == "";
              })
              .toArray();
            if (savingCardPackage2.length > 0) {
              this.$message.error({
                duration: 3000,
                message: "赠额范围下数量不能为空",
              });
              return;
            }
          }
          that.modalLoading = true;
          if (this.isAdd) {
            API.create(para)
              .then((res) => {
                if (res.StateCode == 200) {
                  this.$message.success({
                    message: "创建成功",
                    duration: 2000,
                  });
                  this.search();
                } else {
                  this.$message.error({
                    message: res.Message,
                    duration: 2000,
                  });
                }
                this.dialogVisible = false;
              })
              .finally(function () {
                that.modalLoading = false;
              });
          } else {
            API.update(para)
              .then((res) => {
                if (res.StateCode == 200) {
                  this.$message.success({
                    message: "编辑成功",
                    duration: 2000,
                  });
                  this.search();
                } else {
                  this.$message.error({
                    message: res.Message,
                    duration: 2000,
                  });
                }
                this.dialogVisible = false;
              })
              .finally(function () {
                that.modalLoading = false;
              });
          }
        }
      });
    },
    /** 新增时 获取 商品信息  分类   */
    getGoodsDataNewWork() {
      this.productData();
      this.projectData();
      this.entityData();
      this.categoryData();
      this.generalCardData();
      this.timeCardData();
      this.savingCardData();
    },
    /**    */
    Memo_change(val) {
      let that = this;
      that.ruleForm_add.Memo = val;
    },
    /**    */
    ImageListChange(val) {
      let that = this;
      that.ruleForm_add.ImageList = val;
    },

    /**  请求详情  */
    getPackageCardDetails(ID) {
      let that = this;
      let param = { ID: ID };
      API.getPackageCardDetails(param)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$set(that.ruleForm_add, "ImageList", []);
            that.$set(that.ruleForm_add, "Memo", "");
            res.Data.ImageUrlList.forEach((item) => {
              utils.getCanvasBase64(item.ImageUrl).then((val) => {
                that.ruleForm_add.ImageList.push({ url: val });
              });
            });
            that.ruleForm_add.Memo = res.Data.Memo;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.isPackageCardEdit = permission.permission(
        to.meta.Permission,
        "iBeauty-Goods-PackageCard-Update"
      );
      vm.isPackageCardNew = permission.permission(
        to.meta.Permission,
        "iBeauty-Goods-PackageCard-Create"
      );
    });
  },
  mounted() {
    const that = this;
    that.isPackageCardEdit = permission.permission(
      that.$route.meta.Permission,
      "iBeauty-Goods-PackageCard-Update"
    );
    that.isPackageCardNew = permission.permission(
      that.$route.meta.Permission,
      "iBeauty-Goods-PackageCard-Create"
    );
    this.handleSearch();
    this.productData();
    this.projectData();
    this.entityData();
    this.categoryData();
    this.generalCardData();
    this.timeCardData();
    this.savingCardData();
  },
};
</script>

<style  lang="scss">
.PackageCard {
  .radio_input {
    width: 150px;
    margin-left: 15px;
  }

  .el-table {
    .caret-wrapper {
      height: 23px;
      .ascending {
        top: 0px;
      }
      .descending {
        bottom: 1px;
      }
    }
  }
  .packageShow {
    .el-form-item__label {
      color: #666;
    }
  }
  .el-scrollbar_height {
    height: 60vh;
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
  .el-scrollbar_height_range {
    height: 35vh;
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
}
</style>
